import {useMemo} from 'react';
import useMediaQuery from "./use-media-query";


export const DOTS = '...';

const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({length}, (_, idx) => idx + start);
};

type usePaginationArguments = {
    totalCount: number,
    pageSize: number,
    siblingCount: number,
    currentPage: number,
}

export const usePagination = ({
                                  totalCount,
                                  pageSize,
                                  siblingCount = 1,
                                  currentPage
                              }: usePaginationArguments) => {
    const isMobileSize = useMediaQuery('(max-width: 599px)');

    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize);

        const totalPageNumbers = siblingCount + 5;
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount;
            if (isMobileSize) {
                leftItemCount = 1 + 2 * siblingCount;
            } else {
                leftItemCount = 2 + 2 * siblingCount;
            }

            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount;
            if (isMobileSize) {
                rightItemCount = 1 + 2 * siblingCount;
            } else {
                rightItemCount = 2 + 2 * siblingCount;
            }

            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange;
            if (isMobileSize) {
                middleRange = range(leftSiblingIndex + 1, rightSiblingIndex - 1);
            } else {
                middleRange = range(leftSiblingIndex + 1, rightSiblingIndex);
            }
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [totalCount, pageSize, siblingCount, currentPage, isMobileSize]);

    return paginationRange;
};
