// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var category = "news-item-module--category--f7c0d";
export var container = "news-item-module--container--317ee";
export var date = "news-item-module--date--6cfbe";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var itemHeader = "news-item-module--itemHeader--f8390";
export var mobileDate = "news-item-module--mobileDate--3d857";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var skeletonImg = "news-item-module--skeletonImg--d72a8";
export var skeletonImgContainer = "news-item-module--skeletonImgContainer--3aecd";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";
export var title = "news-item-module--title--5967e";