// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var arrow = "pagination-module--arrow--5f511";
export var disabled = "pagination-module--disabled--a3530";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var dots = "pagination-module--dots--83475";
export var left = "pagination-module--left--c403d";
export var paginationContainer = "pagination-module--paginationContainer--35ac1";
export var paginationItem = "pagination-module--paginationItem--b6502";
export var paginationNumber = "pagination-module--paginationNumber--1e065";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var selected = "pagination-module--selected--df65e";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";