import React from "react";
import * as styles from "../../styles/components/news/news-item.module.scss";
import Skeleton from "react-loading-skeleton";

export const SkeletonNewsItem = () => {
    return (
        <div className={styles.container}>
            <Skeleton width='50%' height={25}/>
            <div className={styles.skeletonImgContainer}>
                <Skeleton className={styles.skeletonImg}/>
            </div>
            <Skeleton height={40} />
            <Skeleton width='25%' />
        </div>
    )
}