import React, {useEffect, useState} from "react";
import {Layout} from "../components/Layout";
import * as styles from "../styles/pages/news.module.scss"
import {useSelector} from "react-redux";
import {RootState} from "../store/reducers";
import {NewsCategoryPageModel, NewsPageModel} from "../store/models/news-page";
import {useActions} from "../store/hooks";
import NewsItem from "../components/news/NewsItem";
import NewsNav from "../components/news/NewsNav";
import StyledText, {TextStyles} from "../components/typography";
import Pagination from "../components/Pagination";
import {QueryModel} from "../store/models/query";
import {scrollToTop} from "../helpers/scroll-to-top";
import SEO from "../components/SEO";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import {MenuModel} from "../store/models/menu";
import {CategoryLabelsModel} from "../store/models/category-labels";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";
import {SkeletonNewsItem} from "../components/common/SkeletonNewsItem";
import * as Scroll from "react-scroll";

const PAGE_SIZE = 10;

type NewsPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse,
        categoryLabels: StrapiResponse,
        category: string,
        newsCategoryPage: StrapiResponse
    },
    location: any
}

const NewsPage = (props: NewsPageProps) => {
    const { category, page, newsCategoryPage, menu, categoryLabels } = props.serverData;
    const newsPageModel: NewsPageModel = NewsPageModel.fromJSON(page);
    const newsCategoryPageModel: NewsCategoryPageModel | null = newsCategoryPage ? NewsCategoryPageModel.fromJSON(newsCategoryPage) : null;
    const menuModel: MenuModel = MenuModel.fromJSON(menu);
    const categoryLabelsModel: CategoryLabelsModel = CategoryLabelsModel.fromJSON(categoryLabels);
    const [scrolling, setScrolling] = useState(false);
    const Events = Scroll.Events;

    const newsQuery: QueryModel = useSelector((state: RootState) => state.newsStore.query);
    const meta = useSelector((state: RootState) => state.newsStore.meta);
    const news = useSelector((state: RootState) => state.newsStore.news);
    const loading = useSelector((state: RootState) => state.newsStore.loading);
    const {fetchNewsStore, setNewsQuery} = useActions();

    useEffect(() => {
        setNewsQuery({
            sort: {
                createdDate: 'DESC'
            },
            pagination: {
                page: 1,
                pageSize: PAGE_SIZE
            },
            filters: category ? {category: {slug: {$eq: category}}} : null
        })
        fetchNewsStore();
    }, [])

    useEffect(() => {
        Events.scrollEvent.register('begin', () => {
            setScrolling(true);
        });

        Events.scrollEvent.register('end', () => {
            setScrolling(false);
        });

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        }
    }, [])

    const handleChangePage = (page: number) => {
        setNewsQuery({
            pagination: {
                page,
                pageSize: PAGE_SIZE,
            }
        });
        fetchNewsStore();
        scrollToTop();
    }

    return (
        <Layout menu={menuModel}>
            <SEO seo={newsCategoryPageModel?.seo || newsPageModel.seo}/>
            <div className={styles.container}>
                <NewsNav title={newsPageModel.title} category={category} categoryLabels={categoryLabelsModel}/>
                <section>
                    <StyledText variant={TextStyles.h1} tag="h1" className={styles.title}>
                        {newsPageModel.title}
                    </StyledText>
                    {news?.length < 1 && !loading &&
                    <StyledText variant={TextStyles.h2Regular} className={styles.noNewsTitle}>
                        {newsPageModel.noNewsTitle}
                    </StyledText>
                    }
                    <div className={styles.grid}>
                        {news && !loading && !scrolling ? news.map((item, index) => (
                                <article key={index}>
                                    <NewsItem newsItem={item} categoryLabelsModel={categoryLabelsModel}/>
                                </article>
                            ))
                            : Array(PAGE_SIZE).fill(1).map(() => (
                                <SkeletonNewsItem/>
                            ))
                        }
                    </div>
                    <Pagination
                        currentPage={newsQuery && newsQuery.pagination?.page}
                        totalCount={meta?.pagination?.total}
                        pageSize={meta?.pagination?.pageSize}
                        onPageChange={(page: number) => handleChangePage(page)}
                    />
                </section>
            </div>
        </Layout>
    )
}

export default NewsPage;

export async function getServerData(context: GetServerDataProps): GetServerDataReturn {
    try {
        const category: string = context.params?.id as string;
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.newsPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);
        const categoryLabels = await strapi.getSingleType(StrapiSingleTypes.categoryLabels);
        let newsCategoryPage = null;

        if (category) {
            try {
                newsCategoryPage = await strapi.getNewsCategoryPage(category)
            } catch (error) {
                newsCategoryPage = null;
            }
        }

        return {
            props: {
                page,
                menu,
                categoryLabels,
                category,
                newsCategoryPage
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}
