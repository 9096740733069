import React from "react";
import {ImageModel} from "../store/models/image";
import ProgressiveImage from "./common/ProgressiveImage";

type ImageContainerProps = {
    aspectRatio: string,
    img: ImageModel | null,
}

const ImageContainer = (props: ImageContainerProps) => {
    const {img, aspectRatio} = props

    return (
        <div >
            {img && (
                <ProgressiveImage
                    image={img}
                    aspectRatio={aspectRatio}
                />
            )}
        </div>
    )
}

export default ImageContainer
