import React from "react";
import StyledText, {TextStyles} from "../typography";
import ImageContainer from "../ImageContainer";
import {NewsItemModel} from "../../store/models/news-page";
import LinkText from "../LinkText";
import {CategoryLabelsModel} from "../../store/models/category-labels";
import * as styles from "../../styles/components/news/news-item.module.scss";
import {Link} from "gatsby";
import currentLocale from "../../helpers/current-locale";

type NewsItemProps = {
    newsItem: NewsItemModel,
    categoryLabelsModel: CategoryLabelsModel
}

const NewsItem = (props: NewsItemProps) => {
    const { newsItem, categoryLabelsModel } = props;
    const path = currentLocale()?.path;
    const newsUrl = `${path ? `/${path}` : ''}/news/`;
    return (
        <div className={styles.container}>
            <div className={styles.itemHeader}>
                <LinkText href={`${newsUrl}category/${newsItem.category}`}
                          textVariant={TextStyles.bodyText2} className={styles.category}>
                    {categoryLabelsModel.categories &&
                        categoryLabelsModel.categories.find((e) => (e.category === newsItem.category))?.label || ' '
                    }
                </LinkText>
                <StyledText variant={TextStyles.bodyText3} className={styles.mobileDate}>
                    {newsItem.createdDate?.formatted || newsItem.createdAt?.formatted}
                </StyledText>
            </div>
            <Link to={newsUrl + newsItem.slug}>
                <ImageContainer aspectRatio='16/9' img={newsItem?.image} />
            </Link>
            <div>
                <LinkText href={newsUrl + newsItem.slug} textVariant={TextStyles.h3} className={styles.title}>
                    {newsItem?.title}
                </LinkText>
            </div>
            <StyledText variant={TextStyles.bodyText2} className={styles.date}>
                {newsItem.createdDate?.formatted || newsItem.createdAt?.formatted}
            </StyledText>
        </div>
    )
}

export default NewsItem
