import React from 'react';
import {DOTS, usePagination} from "../helpers/use-pagination";
import * as styles from "../styles/components/pagination.module.scss"
import StyledText, {TextStyles} from "./typography";
import {IconArrow} from "./svg";

const Pagination = (props: any) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul
            className={`${styles.paginationContainer} ${className ? className : ''}`}
        >
            <li
                className={`${styles.paginationItem} ${currentPage === 1 ? styles.disabled : ''}`}
                onClick={currentPage === 1 ? () => {} : onPrevious}
            >
                <div className={`${styles.arrow} ${styles.left}`}>
                    <IconArrow />
                </div>
            </li>
            {paginationRange && paginationRange.map(pageNumber => {
                if (pageNumber === DOTS) {
                    return <li className={`${styles.paginationItem} ${styles.dots}`}>
                        <span />
                        <span />
                        <span />
                    </li>;
                }

                return (
                    <li
                        className={`${styles.paginationItem} ${styles.paginationNumber} ${pageNumber === currentPage ? styles.selected : ''}`}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        <StyledText variant={TextStyles.bodyText3}>
                            {pageNumber < 10 ? '0' + pageNumber : pageNumber}
                        </StyledText>
                    </li>
                );
            })}
            <li
                className={`${styles.paginationItem} ${currentPage === lastPage ? styles.disabled : ''}`}
                onClick={currentPage === lastPage ? () => {} : onNext}
            >
                <div className={styles.arrow}>
                    <IconArrow />
                </div>
            </li>
        </ul>
    );
};

export default Pagination;
