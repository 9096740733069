import React from "react";
import {CategoryLabelsModel} from "../../store/models/category-labels";
import {useActions} from "../../store/hooks";
import StyledText, {TextStyles} from "../typography";
import * as styles from "../../styles/components/news/news-nav.module.scss";
import LinkText from "../LinkText";
import currentLocale from "../../helpers/current-locale";

type NewsNavProps = {
    title: string | null;
    category?: string;
    isPost?: boolean;
    categoryLabels: CategoryLabelsModel
}

const NewsNav = (props: NewsNavProps) => {
    const {title, category, isPost} = props;
    const categoryLabelsModel: CategoryLabelsModel = props.categoryLabels;
    const {setNewsQuery, fetchNewsStore} = useActions();
    const path = currentLocale()?.path;
    const newsUrl: string = `${path ? `/${path}` : ''}/news`;

    const handleFilter = (category: string | null) => {
        setNewsQuery({
            pagination: {
                page: 1,
                pageSize: 10
            },
            filters: category ? {category: {slug: {$eq: category}}} : null
        });
        fetchNewsStore();
    }

    return (
        <nav className={isPost ? styles.post : ''}>
            <StyledText variant={TextStyles.h1} tag="h1" className={styles.title}>
                {title}
            </StyledText>
            <div className={styles.labelsContainer}>
                {categoryLabelsModel.allCategoriesLabel &&
                  <LinkText
                    href={newsUrl}
                    textVariant={TextStyles.link}
                    onClick={() => handleFilter(null)}
                    isUnderline
                    className={!category ? styles.selected : ''}
                  >
                      {categoryLabelsModel.allCategoriesLabel}
                  </LinkText>
                }
                {categoryLabelsModel.categories && categoryLabelsModel.categories.map((category) => (
                    <LinkText
                        key={category.category}
                        href={`${newsUrl}/category/${category.category}`}
                        textVariant={TextStyles.link}
                        onClick={() => handleFilter(category.category)}
                        isUnderline
                        className={props.category === category.category ? styles.selected : ''}
                    >
                        {category.label}
                    </LinkText>
                ))}
            </div>
        </nav>
    )
}

export default NewsNav
